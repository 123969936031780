import objectFitImages from 'object-fit-images';
import Swiper from 'swiper';

objectFitImages();

document.addEventListener('DOMContentLoaded', () => {
	// DOM loaded
	let lastScroll = 0;

	// Tableau elements déclencheurs changement couleur dash
	window.addEventListener('scroll', () => {
		const elts = [
			'section #description h2',
			'section #tabs',
			'section #map h2',
			'section #expertises h2'
		];

		const navs = [
			'description',
			'services',
			'map',
			'expertises'
		];

		// Changement de couleur dash
		elts.forEach( (e, index) => {
			if(getElementOffset( document.querySelector(e) ).top - window.innerHeight / 3 < window.pageYOffset){
				document.querySelector('.dash').children[index+1].style.transform = 'translate(0, 0%)';
			} else {
				document.querySelector('.dash').children[index+1].style.transform = 'translate(0, 100%)';
			}
		});

		let target;
		// Changement menu item actif
		navs.forEach( (e, index) => {
			if(getElementOffset( document.querySelector('#' + e) ).top - document.querySelector('nav').offsetHeight < window.pageYOffset){
				target = e;
			}
		});

		document.querySelectorAll('nav .nav li button').forEach( a => {
			a.classList.remove('active');
		});
		if(target){
			document.querySelector('nav .nav li button[data-target="' + target + '"]').classList.add('active');
		}

		// Gestion hauteur menu
        document.querySelector('nav').classList.toggle('small', window.pageYOffset > 0);

        // Gestion apparitions images localisation
        if(getElementOffset( document.querySelector('section #map .localisations') ).top - window.innerHeight / 1.5 < window.pageYOffset){
        	document.querySelector('section #map .localisations').classList.add('visible');
        } else {
        	document.querySelector('section #map .localisations').classList.remove('visible');
        }

        // Gestion apparitions images localisation
        if(getElementOffset( document.querySelector('section #description .img-container') ).top - window.innerHeight / 2 < window.pageYOffset){
        	document.querySelector('section #description .img-container').classList.add('visible');
        } else {
        	document.querySelector('section #description .img-container').classList.remove('visible');
        }

        lastScroll = window.pageYOffset;
	});

	// Ecouteur click navigation
	const nav = document.querySelectorAll('nav .nav li');
	Array.from(nav).forEach( li => {
		li.querySelector('button').addEventListener('click', element => {
			let topValue;
			if(window.innerWidth < 769){
				topValue = getElementOffset( document.querySelector(`#${element.target.dataset.target}`) ).top - 70;
			} else {
				topValue = getElementOffset( document.querySelector(`#${element.target.dataset.target}`) ).top - document.querySelector('nav').offsetHeight - 50;
			}
			window.scrollTo({
				top: topValue,
				behavior: 'smooth'
			});
			if(window.innerWidth < 769){
				document.querySelector('nav').classList.remove('open');
			}
		});
	});

	// Ecouteur click sur logo
	document.querySelector('nav .logo').addEventListener('click', element => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	});

	// Slider texte header
	let sliderHead = new Swiper('.slider', {
	    speed: 400,
	    autoHeight: true,
	    autoplay: 3500
	});

	// Play / pause slider header
	document.querySelector('header .stop').addEventListener('click', e => {
		if(e.target.classList.contains('stopped')){
			sliderHead.startAutoplay();
			e.target.classList.remove('stopped');
		} else {
			sliderHead.stopAutoplay();
			e.target.classList.add('stopped');
		}
	});

	const tabs = Array.from(document.querySelectorAll('section #tabs .filters li')),
		  imgs = document.querySelector('section #tabs .figure');

	// Slider tabs
	let sliderTabs = new Swiper('.tabs-container .wrapper', {
	    speed: 400,
	    simulateTouch: false
	});

	document.querySelector('section #tabs .tabs-container .controls .prev').addEventListener('click', () => {
		sliderTabs.slidePrev();
	});

	document.querySelector('section #tabs .tabs-container .controls .next').addEventListener('click', () => {
		sliderTabs.slideNext();
	});

	// Gestion changement slide via pagination
	tabs.forEach( e => {
		e.addEventListener('click', elt => {
			sliderTabs.slideTo( tabs.indexOf(elt.target.parentElement) );
		});
	});

	// Gestion image et etat actif de la pagination au changement de slide
	sliderTabs.on('onSlideChangeStart', () => {
		imgs.querySelector('.visible').classList.remove('visible');
		imgs.children[ sliderTabs.activeIndex ].classList.add('visible');

		document.querySelector('section #tabs .filters li.active').classList.remove('active');
		document.querySelector('section #tabs .filters').children[sliderTabs.activeIndex].classList.add('active');

		if(sliderTabs.activeIndex == 0){
			document.querySelector('section #tabs .tabs-container .controls .prev').classList.add('hidden');
		} else {
			document.querySelector('section #tabs .tabs-container .controls .prev').classList.remove('hidden');
		}

		if(sliderTabs.activeIndex == imgs.children.length - 1){
			document.querySelector('section #tabs .tabs-container .controls .next').classList.add('hidden');
		} else {
			document.querySelector('section #tabs .tabs-container .controls .next').classList.remove('hidden');
		}
	});

	// Ecouteur click mentions légales
	document.querySelector('footer .legal button').addEventListener('click', () => {
		document.querySelector('aside').classList.add('visible');
		return false;
	});

	// Ecouleur fermeture mentions légales
	document.querySelector('aside .close').addEventListener('click', () => {
		document.querySelector('aside').classList.remove('visible');
	});

	// Ecouteurs click menu burger
	document.querySelector('nav .burger').addEventListener('click', () => {
		document.querySelector('nav').classList.toggle('open');
	});

	const images = document.querySelectorAll('img[data-src]');
	Array.from(images).forEach( elt => {
		if(window.devicePixelRatio == 2){
			elt.src = elt.dataset.srcset;
		} else {
			elt.src = elt.dataset.src;
		}
	});
});

// Récupération offset top et left element en fonction du document
function getElementOffset(el) {
    const rect = el.getBoundingClientRect();

    return {
        top: rect.top + window.pageYOffset,
        left: rect.left + window.pageXOffset,
    };
}